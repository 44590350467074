<template>
    <div style="height: 1000px">
        <Waterfall
            :list="items"
            :gutter="10"
            :gridWidth="gridWidth"
            :breakpoints="{
                1900: {
                    //当屏幕宽度小于等于1900
                    rowPerView: 10,
                },
                1440: {
                    //当屏幕宽度小于等于1200
                    rowPerView: 8,
                },
                900: {
                    //当屏幕宽度小于等于800
                    rowPerView: 6,
                },
            }"
            backgroundColor="rgb(255, 255, 255)"
            ref="waterfall"
        >
            <template slot="item" slot-scope="props">
                <div class="card">
                    <!-- {{ props.item.coverUrl }} -->
                    <img :src="props.item.coverUrl" alt="" width="100%" />
                </div>
            </template>
        </Waterfall>
    </div>
</template>

<script>
import { LazyImg, Waterfall } from "vue-waterfall-plugin";
import "vue-waterfall-plugin/dist/style.css";
export default {
    name: "VideoVue22Test",
    components: {
        // LazyImg,
        Waterfall,
    },
    data() {
        return {
            items: [
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
                {
                    id: "1700121496581820417",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-10-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-08T12:18:46.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-24T08:01:16.000+00:00",
                },
                {
                    id: "1699333400445566977",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-09-06",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-09-06T08:07:09.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-09-06T08:07:09.000+00:00",
                },
                {
                    id: "1694543676367396865",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-24",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-24T02:54:30.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-24T02:54:30.000+00:00",
                },
                {
                    id: "1693869252894937089",
                    intputCount: 1000,
                    title: "123",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/362897107360677957/2023-08-14/460804020966012101.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-22T06:14:35.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-10-20T03:42:34.000+00:00",
                },
                {
                    id: "1692412919960248321",
                    intputCount: 1000,
                    title: "极速裂变工程-2023-08-18",
                    coverUrl:
                        "https://cncspicture.oss-cn-shanghai.aliyuncs.com/d3c9b91b-3566-489c/2023-07-01/444881040251562949.jpg",
                    status: -3,
                    projectType: "BlendClip",
                    createUserId: "362897107360677957",
                    createUser: "test",
                    createTime: "2023-08-18T05:47:38.000+00:00",
                    updateUserId: "362897107360677957",
                    updateUser: "test",
                    updateTime: "2023-08-18T05:49:22.000+00:00",
                },
            ],
        };
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped>
</style>