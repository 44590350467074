var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"1000px"}},[_c('Waterfall',{ref:"waterfall",attrs:{"list":_vm.items,"gutter":10,"gridWidth":_vm.gridWidth,"breakpoints":{
            1900: {
                //当屏幕宽度小于等于1900
                rowPerView: 10,
            },
            1440: {
                //当屏幕宽度小于等于1200
                rowPerView: 8,
            },
            900: {
                //当屏幕宽度小于等于800
                rowPerView: 6,
            },
        },"backgroundColor":"rgb(255, 255, 255)"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('div',{staticClass:"card"},[_c('img',{attrs:{"src":props.item.coverUrl,"alt":"","width":"100%"}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }